/* Form Container Styles
.form-container {
    background: #f0f0f0;   
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 2em auto;
    padding: 2em;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
}

.form-container h2 {
    margin-bottom: 1.5em;
    font-size: 2em;
    color: #333;
}

.form-group {
    margin-bottom: 1.5em;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: #333;
}

.form-group input[type="text"],
.form-group input[type="tel"],
.form-group input[type="url"],
.form-group input[type="file"],
.form-group textarea {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    background: #fff;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="tel"]:focus,
.form-group input[type="url"]:focus,
.form-group input[type="file"]:focus,
.form-group textarea:focus {
    border-color: #43cea2; 
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
}

.form-group textarea {
    resize: vertical;
}

.form-group button {
    width: 100%;
    padding: 0.75em;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    background: linear-gradient(to right, #185a9d, #43cea2); 
    color: #fff;
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 1em;
}

.form-group button:hover {
    background: linear-gradient(to right, #43cea2, #185a9d); 
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} */
.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
  }
  
  .signature-button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 15px;
    cursor: pointer;
  }
  
  .signature-button:hover {
    background: #0056b3;
  }
  
  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%); 
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .sigCanvas {
    border: 1px solid #ccc;
    background-color: white;
  }