.App {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #1DA1F2;
  }
  
  a {
    text-decoration: none;
    color: white;
    background-color: #1DA1F2;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  a:hover {
    background-color: #0d8ddb;
  }
  