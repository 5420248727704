/* body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} 
.form-container {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}
.form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}
.form-group {
    margin-bottom: 15px;
}
.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}
.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}
.form-group input[type="file"] {
    padding: 10px;
}
.form-group button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.form-group button:hover {
    background-color: #0056b3;
} */


/* Form Container Styles */
.form-container {
    background: #f0f0f0; /* Light grey background */
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 2em auto;
    padding: 2em;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
}

.form-container h2 {
    margin-bottom: 1.5em;
    font-size: 2em;
    color: #333;
}

.form-group {
    margin-bottom: 1.5em;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: #333;
}

.form-group input[type="file"],
.form-group input[type="text"] {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    background: #fff;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input[type="file"]:focus,
.form-group input[type="text"]:focus {
    border-color: #43cea2; /* Primary Green */
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
}

.form-group button {
    width: 100%;
    padding: 0.75em;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    background: linear-gradient(to right, #185a9d, #43cea2); /* Gradient background */
    color: #fff;
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.form-group button:hover {
    background: linear-gradient(to right, #43cea2, #185a9d); /* Reverse gradient */
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

/* Keyframe Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
