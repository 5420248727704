/* Features Section Styles */
.features-section {
    background: linear-gradient(to right, #f0f0f0, #e0e0e0); /* Light gradient background */
    color: #333;
    padding: 4em 1em;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.features-content {
    max-width: 1200px;
    margin: auto;
    padding: 2em;
}

.features-content h2 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.features-grid {
    display: flex;
    justify-content: center;
    gap: 2em;
    flex-wrap: wrap;
}

.feature-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    padding: 2em;
    max-width: 300px;
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.feature-card h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}

.feature-card p {
    font-size: 1em;
    line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
    .features-grid {
        flex-direction: column;
        align-items: center;
    }

    .feature-card {
        width: 80%;
        margin-bottom: 1em;
    }
}
