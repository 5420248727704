/* Footer Styles */
.footer {
    background: linear-gradient(to right, #185a9d, #43cea2); /* Gradient background */
    color: #fff;
    padding: 2em 1em;
    text-align: center;
    position: relative;
}

.footer-content {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
}

.footer-logo h3 {
    font-size: 2em;
    margin: 0;
}

.footer-links {
    display: flex;
    gap: 1.5em;
    flex-wrap: wrap;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1em;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #ffd700; /* Accent Gold */
}

.footer-social {
    display: flex;
    gap: 1em;
}

.footer-social a {
    color: #fff;
    font-size: 1.5em;
    transition: color 0.3s, transform 0.3s;
}

.footer-social a:hover {
    color: #ffd700; /* Accent Gold */
    transform: scale(1.2);
}

.footer-bottom {
    margin-top: 2em;
    font-size: 0.9em;
    color: #ccc;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        justify-content: center;
    }

    .footer-social {
        justify-content: center;
    }
}
