
#hero {
    /* background: url('C:/Users/Sushmitha/crud-app/frontend/src/Images/DALL·E 2024-07-13 12.11.27 - A professional and sleek business-themed hero image for a website. The image should include a modern office background with a clean desk, a laptop, an.webp')no-repeat center center/cover; */
    background: linear-gradient(to right, #185a9d, #43cea2);
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 1em;
}

.hero-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 2em;
    border-radius: 10px;
}

.hero-content h1 {
    font-size: 3em;
    margin: 0.5em 0;
}

.hero-content p {
    font-size: 1.5em;
    margin: 0.5em 0;
}

.cta-button {
    background: #ffd700; 
    color: #000;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2em;
    transition: background 0.3s, transform 0.3s;
}

.cta-button:hover {
    background: #ffcc00;
    transform: scale(1.1);
} 





