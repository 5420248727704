 /* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 400px;
    position: relative;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-logo {
    height: 100px;
    margin-bottom: 20px;
  }
  
  .modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .continue-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .google-button {
    background-color: #db4437;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .continue-button:hover,
  .google-button:hover {
    opacity: 0.8;
  }
   */
/* 
   .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  input {
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
   */ 
/* 
   .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
  }
  
  .modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
  
  h2 {
    margin-bottom: 1rem;
    color: #4CAF50;
  }
  
  input {
    display: block;
    margin: 1rem 0;
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 0.75rem;
    width: 100%;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049;
  }
   */

   /* Reset some basic elements for cross-browser consistency */
 /* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}  

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.logo img {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

h2 {
  margin-bottom: 1rem;
  color: #333;
}

p {
  margin-bottom: 1.5rem;
  color: #777;
}

input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.continue-button {
  background-color: #FF6B6B;
  color: white;
}

.continue-button:hover {
  background-color: #e55d5d;
}

.google-button {
  background-color: #4285F4;
  color: white;
  margin-top: 0.5rem;
}

.google-button:hover {
  background-color: #357ae8;
}

.resend-button {
  background-color: transparent;
  color: #FF6B6B;
  text-decoration: underline;
  margin-top: 1rem;
}

.resend-button:hover {
  background-color: transparent;
  color: #e55d5d;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    padding: 1.5rem;
  }

  input {
    padding: 0.5rem;
  }

  button {
    padding: 0.5rem;
  }
}*/

/* Reset some basic elements for cross-browser consistency */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.logo img {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

h2 {
  margin-bottom: 1rem;
  color: #333;
}

p {
  margin-bottom: 1.5rem;
  color: #777;
}

.input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.continue-button {
  background-color: #FF6B6B;
  color: white;
}

.continue-button:hover {
  background-color: #e55d5d;
}

.continue-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.google-button {
  background-color: #4285F4;
  color: white;
  margin-top: 0.5rem;
}

.google-button:hover {
  background-color: #357ae8;
}

.resend-button {
  background-color: transparent;
  color: #FF6B6B;
  text-decoration: underline;
  margin-top: 1rem;
}

.resend-button:hover {
  background-color: transparent;
  color: #e55d5d;
}

.error {
  color: red;
  margin-bottom: 1rem;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    padding: 1.5rem;
  }

  .input {
    padding: 0.5rem;
  }

  .button {
    padding: 0.5rem;
  }
}

