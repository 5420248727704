/* Contact Section Styles */
.contact-section {
    background: linear-gradient(to right, #43cea2, #185a9d); /* Gradient background */
    color: #fff;
    padding: 4em 1em;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.contact-content {
    max-width: 800px;
    margin: auto;
    padding: 2em;
    background: rgba(255, 255, 255, 0.9); /* Slightly translucent background for content */
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.contact-content h2 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    color: #333;
}

.contact-content p {
    font-size: 1.2em;
    margin-bottom: 1.5em;
    color: #666;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

form label {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5em;
}

form input, form textarea {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    background: #fff;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
}

form input:focus, form textarea:focus {
    border-color: #43cea2;
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
}

form textarea {
    resize: vertical;
}

form .form-submit {
    background: #43cea2;
    color: #fff;
    border: none;
    padding: 0.75em 2em;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

form .form-submit:hover {
    background: #3bb895;
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.error {
    color: red;
    font-size: 0.9em;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-content {
        padding: 1em;
    }

    .contact-content h2 {
        font-size: 2em;
    }

    form input, form textarea, form .form-submit {
        font-size: 1em;
    }
}
