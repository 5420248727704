/* Actions Section Styles */
.actions-section {
    background: linear-gradient(to right, #43cea2, #185a9d); /* Gradient background */
    color: #fff;
    padding: 4em 1em;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.actions-section h2 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.actions-buttons {
    display: flex;
    justify-content: center;
    gap: 1.5em;
    flex-wrap: wrap;
}

.action-button {
    padding: 1em 2em;
    border: none;
    border-radius: 50px;
    font-size: 1.2em;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.action-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.action-button.scan {
    background: linear-gradient(to right, #ff6e7f, #bfe9ff);
    color: #fff;
}

.action-button.scan:hover {
    background: linear-gradient(to left, #ff6e7f, #bfe9ff);
}

 .action-button.upload {
    background: linear-gradient(to right, #42e695, #3bb2b8);
    color: #fff;
}

 .action-button.upload:hover {
    background: linear-gradient(to left, #42e695, #3bb2b8);
}

.action-button.manual {
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    color: #fff;
}

.action-button.manual:hover {
    background: linear-gradient(to left, #ff7e5f, #feb47b);
}

/* Responsive Design */
@media (max-width: 768px) {
    .actions-buttons {
        flex-direction: column;
        align-items: center;
    }

    .action-button-a, .action-button {
        width: 80%;
        margin-bottom: 1em;
    }
}
