/* Basic styles for the header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-links li {
    margin: 0;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
  }
  
  .profile-icon {
    cursor: pointer;
    margin-right: 20px;
  }
  
  .mobile-menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .nav-links {
      display: none;
    }
  
    .nav-mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      background-color: #333;
    }
  
    .nav-mobile .nav-links {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .mobile-menu-icon {
      display: flex;
    }
  
    .nav-desktop {
      display: none;
    }
  }
  