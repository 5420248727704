/* About Section Styles */
.about-section {
    background: linear-gradient(to right, #f0f0f0, #e0e0e0); /* Light gradient background */
    color: #333;
    padding: 4em 1em;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.about-content {
    max-width: 800px;
    margin: auto;
    padding: 2em;
    background: rgba(255, 255, 255, 0.9); /* Slightly translucent background for content */
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.about-content h2 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.about-content p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 1em;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-content {
        padding: 1em;
    }

    .about-content h2 {
        font-size: 2em;
    }

    .about-content p {
        font-size: 1em;
    }
}
