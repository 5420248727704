/* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  .logo img {
    height: 50px;
  }
  
  .auth-buttons {
    display: flex;
    gap: 10px;
  }
  
  .auth-buttons button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .auth-buttons .sign-in {
    background-color: #28a745;
  }
  
  .auth-buttons button:hover {
    opacity: 0.8;
  }
   */

/* Basic styles for the header */
/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.icon-container {
  display: flex;
  align-items: center;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-buttons button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.auth-buttons .sign-in {
  background-color: #28a745;
}

.auth-buttons button:hover {
  opacity: 0.8;
}

.profile-icon {
  cursor: pointer;
  margin-left: 20px;
}

.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
}

/* Mobile styles 
@media (max-width: 768px) {
  .auth-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .mobile-menu-icon {
    display: flex;
  }
} */
.Intro {
  text-align: center;
}

.Intro-header {
  background-color: #282c34;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.Intro-logo {
  font-size: 1.5em;
  font-weight: bold;
}

.Intro-nav {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.Intro-button {
  background-color: #61dafb;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  color: #282c34;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.Intro-button:hover {
  background-color: #21a1f1;
}

.Intro-main {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  /* background: url('banner-background.jpg') no-repeat center center;
  background-size: cover; */
  background-color: #21a1f1
}

.Intro-banner {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.Intro-banner h1 {
  margin: 0;
  font-size: 2em;
  color: #282c34;
}

.Intro-banner p {
  margin: 10px 0;
  font-size: 1.2em;
  color: #282c34;
}

.Intro-link-button {
  background-color: #61dafb;
  color: #282c34;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.Intro-link-button:hover {
  background-color: #21a1f1;
}

@media (max-width: 600px) {
  .Intro-header {
    flex-direction: column;
    gap: 10px;
  }

  .Intro-nav {
    flex-direction: column;
    gap: 10px;
  }
}
