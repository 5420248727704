/* 
         body {
            font-family: 'Arial', sans-serif;
            background-color: #f0f2f5;
            margin: 0;
            padding: 20px;
        } 
        h1 {
            text-align: center;
            color: #333;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        th, td {
            padding: 15px;
            border: 1px solid #ddd;
            text-align: left;
        }
        th {
            background-color: #007BFF;
            color: white;
        }
        tr:nth-child(even) {
            background-color: #f9f9f9;
        }
        tr:hover {
            background-color: #f1f1f1;
        }
        .button-column {
            text-align: center;
        }
        .button-column button {
            background-color: #fa5858;
            border: none;
            color: white;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            margin: 4px 2px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s;
        }
        .button-column button:hover {
            background-color: #e22727;
        }

        .button-column1{
            text-align: center;
        }
        .button-column1 button {
            background-color: #4def73;
            border: none;
            color: white;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            margin: 4px 2px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s;
        }
        .button-column1 button:hover {
            background-color: #2c9a43;
        }
 */
/* 
 /* src/Read.css */
/* body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  } 
  
  .read-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-bar input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-bar button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-bar button:hover {
    background-color: #21a1f1;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    overflow-x: auto;
    display: block;
  }
  
  .user-table th, .user-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
  }
  
  .user-table th {
    background-color: #282c34;
    color: white;
  }
  
  .user-table td input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .user-table td button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .user-table td button:hover {
    background-color: #21a1f1;
  }
  
  .bulk-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .bulk-actions a, .bulk-actions button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    text-align: center;
  }
  
  .bulk-actions a:hover, .bulk-actions button:hover {
    background-color: #21a1f1;
  }
  
  @media (max-width: 768px) {
    .user-table th, .user-table td {
      padding: 8px;
    }
  
    .search-bar {
      flex-direction: column;
      gap: 10px;
    }
  
    .bulk-actions {
      flex-direction: column;
      gap: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .user-table th, .user-table td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  
    .user-table th {
      background-color: #444;
    }
  } */
  
  /* src/Read.css */
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }
  
  .read-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-bar .search-input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-bar .search-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-bar .search-button:hover {
    background-color: #21a1f1;
  }
  
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .user-table th, .user-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
  }
  
  .user-table th {
    background-color: #282c34;
    color: white;
  }
  
  .user-table td input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .user-table td button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .user-table td button:hover {
    background-color: #21a1f1;
  }
  
  .bulk-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .bulk-actions a, .bulk-actions button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    text-align: center;
  }
  
  .bulk-actions a:hover, .bulk-actions button:hover {
    background-color: #21a1f1;
  }
  
  @media (max-width: 768px) {
    .search-bar {
      flex-direction: column;
      gap: 10px;
    }
  
    .bulk-actions {
      flex-direction: column;
      gap: 10px;
    }
  }
  