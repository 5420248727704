.profile-modal {
    position: fixed; /* Use fixed position to keep the modal in place */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other elements */
  }
  
  .profile-modal-content {
    background-color: #1c1c1c; /* Updated background color for better visibility */
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    max-height: 90%; /* Limit the height of the modal */
    overflow-y: auto; /* Enable scrolling if the content overflows */
    position: relative;
    color: white; /* Updated text color for better visibility */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white; /* Updated text color for better visibility */
    cursor: pointer;
  }
  
  .profile-form .form-row {
    display: flex;
    gap: 20px;
  }
  
  .profile-form .form-group {
    flex: 1;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .profile-form .form-group label {
    margin-bottom: 5px;
    color: #fff; /* Make the label color white for better visibility */
  }
  
  .profile-form .form-group input,
  .profile-form .form-group textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
  }
  
  .profile-details {
    text-align: center;
  }
  
  .profile-details .profile-image {
    display: block;
    margin: 0 auto 20px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .profile-details p {
    margin: 10px 0;
  }
  
  .profile-details a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .profile-details a:hover {
    text-decoration: underline;
  }
  
  .button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .submit-button {
    background-color: #28a745;
    display: block;
    margin: 20px auto; /* Center the button horizontally */
    width: 200px; /* Set the button width to reduce its size */
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  .edit-button {
    background-color: #ffc107;
  }
  
  .edit-button:hover {
    background-color: #e0a800;
  }
  